















import { Component, Vue, Prop } from 'vue-property-decorator'

import WysiwygInput  from '@/components/editors/Wysiwyg.vue'
import SmallLoader   from '@/components/loaders/SmallLoader.vue'

@Component({
  components: {
    SmallLoader,
    WysiwygInput,
  }
})
export default class DeleteProposalModal extends Vue {
  @Prop({ required: true })
  private readonly title!: string

  @Prop({ default: false })
  private readonly loading!: boolean

  private message: string = ''

  private get validated (): boolean {
    return this.message !== '' && this.message !== '<p><br></p>' && this.message !== '<p><br /></p>' && this.message !== '<div><br></div>' && this.message !== '<div><br /></div>' && this.message.length !== 0
  }

  private submit (): void {
    this.$emit('submit', this.message || undefined)
  }
}
