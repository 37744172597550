import { render, staticRenderFns } from "./DeleteModal.vue?vue&type=template&id=bdd9879e&scoped=true&"
import script from "./DeleteModal.vue?vue&type=script&lang=ts&"
export * from "./DeleteModal.vue?vue&type=script&lang=ts&"
import style0 from "./DeleteModal.vue?vue&type=style&index=0&id=bdd9879e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdd9879e",
  null
  
)

export default component.exports