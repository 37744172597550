


























import { Route } from 'vue-router'
import { projectModule, userModule } from '@/store'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import {
  isFlexibleDocumentItemWithSuggestions
} from '@/helpers/FlexibleDocument'

import SmallLoader  from '@/components/loaders/SmallLoader.vue'
import Suggestion from '@/components/suggestions/Suggestion.vue'

import { AuthUserResource }    from '@/models/users/AuthUserResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { SuggestionResource } from '@/models/suggestions/SuggestionResource'
import { FlexibleDocumentItemResource } from '@/models/flexibleDocument/FlexibleDocumentItemResource'

type dubRoute = Route

// component
@Component({
  components: {
    Suggestion,
    SmallLoader
  }
})

export default class ElementSuggestions extends Vue {

  @Prop()
  private readonly suggestionFilter!: string | null

  @Prop()
  private readonly element!: FlexibleDocumentItemResource

  @Prop({ default: true })
  private readonly readonly!: boolean

  private loading: boolean = false

  @Watch('element.suggestions.data.length')
  private onProposalSuggestionsLength(): void {
    this.$nextTick(() => {
      this.scrollBottom()
    })
  }

  private get filteredSuggestions(): SuggestionResource[] {
    if (isFlexibleDocumentItemWithSuggestions(this.element)) {
      if (this.suggestionFilter) {
        return this.element.suggestions.items.filter((c) => {
          if (this.suggestionFilter === 'resolved') {
            return c.status === 'approved' || c.status === 'declined'
          }
          return c.status === this.suggestionFilter
        })
      }

      return this.element.suggestions.items
    }

    return []
  }

  private get users(): ProjectUserResource[] {
    return projectModule.users
  }

  private async mounted(): Promise<void> {
    this.scrollBottom()
  }

  private async getSuggestions(): Promise<void> {
    if (isFlexibleDocumentItemWithSuggestions(this.element)) {
      this.loading = true
      try {
        await this.element.suggestions.get()
      } finally {
        this.loading = false
      }
    }
  }

  private deleteSuggestion(id: number): void {
    if (isFlexibleDocumentItemWithSuggestions(this.element)) {
      this.element.suggestions.deleteSuggestion(id)
    }
  }

  private refresh(): void {
    this.getSuggestions()
    this.$emit('refresh')
  }

  private scrollBottom(): void {
    const wrapperDiv = this.$refs.wrapper as HTMLElement
    if (!wrapperDiv) return
    wrapperDiv.scrollTop = wrapperDiv.scrollHeight
  }
}
