import { render, staticRenderFns } from "./Element.vue?vue&type=template&id=04c9aa92&scoped=true&"
import script from "./Element.vue?vue&type=script&lang=ts&"
export * from "./Element.vue?vue&type=script&lang=ts&"
import style0 from "./Element.vue?vue&type=style&index=0&id=04c9aa92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c9aa92",
  null
  
)

export default component.exports